<template>

  <!-- Other Overhead -->
  <div id="story-mode-other-overheads">

    <!-- Main Navigation -->
    <main-navigation />
    <!-- /Main Navigation -->

    <!-- Container -->
    <div class="container-1200">
      <loader v-if="!loaded.otherOverheads" />

      <!-- Content -->
      <div v-if="loaded.otherOverheads" class="content">
        <v-app>
          <!-- Page Header -->
          <header class="page-header">

            <!-- Dashboard Header -->
            <dashboard-header
              :title="$t('pages.businessGuide.otherOverheads.otherOverheads')"
              :dashboard-items="dashboardOtherOverheads"
              :class="{ 'stamp': isOtherOverheadCompleted }"
              :guide-visible="guideVisible"
              :guide-visible-first-time="guideVisibleFirstTime"
              :completed-step-name="'otherOverheadsCompleted'"
              hide-quick-tour-button
              @toggle-guide="onGuideExampleVisible"
              @open-comp-dialog="openedDialogProcessCompleted"
              @open-info="openInfo"
              @open-ai-buddy-drawer="aiBuddyDrawerVisible = true"
            />
            <!-- /Dashboard Header -->

            <hr>
          </header>
          <!-- /Page Header -->

          <!-- Info Guide Expand Panel -->
          <info-guide
            v-if="guideVisibleFirstTime"
            class="mb-2"
            type="otherOverheadsExamples"
            visible
            @click="guideVisibleFirstTime = !guideVisibleFirstTime"
          />
          <!-- Info Guide Expand Panel -->
        </v-app>

        <!-- Height Holder For Sticky Header -->
        <div class="height-holder d-none d-md-block" />
        <!-- /Height Holder For Sticky Header -->

        <!-- Charts And Table Placeholders -->
        <div v-if="isOtherOverheadEmpty" class="table-view">
          <el-tabs v-model="activeName">

            <!-- Breakdown -->
            <el-tab-pane :label="$t('breakdown')" name="first">
              <div class="switcher-inner-wrapper">
                <div class="placeholder breakdown" />
              </div>
            </el-tab-pane>
            <!-- /Breakdown -->

            <!-- Timeline -->
            <el-tab-pane :label="$t('timeline')" name="second">
              <div class="switcher-inner-wrapper">
                <div class="placeholder timeline" />
              </div>
            </el-tab-pane>
            <!-- /Timeline -->

            <!-- Table -->
            <el-tab-pane :label="$t('table')" name="third">
              <div class="switcher-inner-wrapper">
                <div class="placeholder table" />
              </div>
            </el-tab-pane>
            <!-- /Table -->

          </el-tabs>
        </div>
        <!-- /Charts And Table Placeholders -->

        <!-- Charts And Table Filled -->
        <div v-if="!isOtherOverheadEmpty" class="table-view filled">
          <el-tabs v-model="activeName">

            <!-- Breakdown -->
            <el-tab-pane :label="$t('breakdown')" name="first">
              <div v-if="activeName === 'first'" class="bg-white">
                <distribution-of-other-overheads
                  class="pt-4"
                  chart-animations
                />
              </div>
            </el-tab-pane>

            <!-- Timeline -->
            <el-tab-pane :label="$t('timeline')" name="second">
              <div v-if="activeName === 'second'" class="bg-white">
                <other-overheads-over-time
                  class="pt-4"
                  chart-animations
                />
              </div>
            </el-tab-pane>
            <!-- /Timeline -->

            <!-- Table -->
            <el-tab-pane :label="$t('table')" name="third">
              <div v-if="activeName === 'third'" class="switcher-inner-wrapper">
                <other-overhead-table class="pt-4" />
              </div>
            </el-tab-pane>
            <!-- /Table -->

          </el-tabs>
        </div>
        <!-- /Charts And Table Filled -->

        <v-app>
          <!-- Header -->
          <business-guide-header>
            <template #right>
              <erase-icon-button
                v-if="removeButtonVisible"
                @click="onEraseFromTemplate"
              />

              <business-guide-search
                v-if="!isOtherOverheadEmpty"
                v-model="searchValue"
                class="mx-1"
              />
              <!-- View Switcher -->
              <view-switcher
                v-if="isOtherOverheadEmpty === false"
                :active-view="$store.state.itemsView.otherOverheadsView"
                @activate-view="onActivateView"
              />
              <!-- /View Switcher -->
            </template>
          </business-guide-header>
          <!-- /Header -->
        </v-app>

        <!-- List Wrapper -->
        <div class="list-wrapper" :class="{ 'active': $store.state.itemsView.otherOverheadsView === 'list' }">
          <el-row :gutter="20" class="d-flex flex-wrap">
            <!-- Completed Overlay -->
            <completed-overlay
              v-if="!canEdit || isOtherOverheadCompleted"
              @click="checkOtherOverheads"
            />
            <!-- /Completed Overlay -->
            <!-- Add Other Overhead -->
            <el-col :md="12" class="d-flex mb-2">
              <add-new-card
                class="width-xl-20 py-2 py-md-0"
                :title="$tc('addOverHead')"
                :disabled="!canEdit || isOtherOverheadCompleted"
                horizontal
                @click="onAddOtherOverhead"
              />
            </el-col>
            <!-- /Add Other Overhead -->

            <!-- Placeholder 1 -->
            <el-col v-if="isOtherOverheadEmpty && $breakpoint.mdAndUp" :md="12">
              <div class="placeholder blue">
                <div class="inner" />
              </div>
            </el-col>
            <!-- /Placeholder 1 -->

            <!-- Placeholder 2 -->
            <el-col v-if="isOtherOverheadEmpty && $breakpoint.mdAndUp" :md="12">
              <div class="placeholder yellow">
                <div class="inner" />
              </div>
            </el-col>
            <!-- /Placeholder 2 -->

            <!-- Tutorial Tooltip TODO - Currently disabled in if directive -->
            <tutorial-tooltip
              v-if="$store.state.user.tutorial.mainBottomBarCompleted === false && 1 === 2"
              :title="'Add customers you want to target'"
              :text="'This is the star of <strong>your show</strong>! Lorem ipsum dolor sit amet, consectetur, adipiscing elit praesent.'"
              :tutorial-name="'otherOverheadCompleted'"
            />
            <!-- /Tutorial Tooltip -->

            <!-- List Items -->
            <el-col
              v-for="otherOverhead in otherOverheadToShowList"
              :key="otherOverhead.id"
              class="d-flex"
              :md="12"
            >
              <div class="d-flex w-100" @click="checkOtherOverheads">
                <set-up-and-projections-card
                  class="mb-2 w-100"
                  :entity="otherOverhead"
                  :name="otherOverhead.name"
                  :date="otherOverheadDate(otherOverhead)"
                  :amount="otherOverheadAmount(otherOverhead)"
                  :frequency="otherOverhead.oneTime ? $t('oneTime') :$t('pages.businessGuide.monthlyAverage')"
                  :type="otherOverhead.type"
                  view="list"
                  :disabled="isOtherOverheadCompleted"
                  @edit="onEditOtherOverhead"
                  @copy="onCopyOtherOverhead"
                  @delete="onDeleteOtherOverhead"
                />
              </div>
            </el-col>
            <!-- /List Items -->
          </el-row>
          <!-- Show All -->
          <el-row v-if="idea.storyMode.forecast.otherOverheads.otherOverheads.length > 7">
            <el-col>
              <show-all-toggle
                :show="showAllOtherOverheadsList"
                @click="showAllOtherOverheadsList = !showAllOtherOverheadsList"
              />
            </el-col>
          </el-row>
          <!-- /Show All -->
        </div>
        <!-- /List Wrapper -->

        <div class="grid-wrapper" :class="{ 'active': $store.state.itemsView.otherOverheadsView === 'grid' }">
          <el-row :gutter="20" class="d-flex flex-wrap">
            <el-col>
              <!-- Completed Overlay -->
              <completed-overlay
                v-if="!canEdit || isOtherOverheadCompleted"
                @click="checkOtherOverheads"
              />
            </el-col>
            <!-- /Completed Overlay -->
            <!-- Add Other Overhead -->
            <el-col :md="8" :xl="6" class="d-flex mb-4 width-lg-20">
              <add-new-card
                class="width-xl-20"
                :title="$tc('addOverHead')"
                :disabled="!canEdit || isOtherOverheadCompleted"
                min-height="345px"
                @click="onAddOtherOverhead"
              />
            </el-col>
            <!-- /Add Other Overhead -->

            <!-- Grid Items -->
            <el-col
              v-for="otherOverhead in otherOverheadToShowGrid"
              :key="otherOverhead.id"
              class="d-flex mb-4 width-lg-20"
              :md="8"
              :xl="6"
            >
              <div class="d-flex w-100" @click="checkOtherOverheads">
                <set-up-and-projections-card
                  class="w-100"
                  :entity="otherOverhead"
                  :name="otherOverhead.name"
                  :date="otherOverheadDate(otherOverhead)"
                  :amount="otherOverheadAmount(otherOverhead)"
                  :frequency="otherOverhead.oneTime ? $t('oneTime') :$t('pages.businessGuide.monthlyAverage')"
                  :type="otherOverhead.type"
                  :disabled="isOtherOverheadCompleted"
                  @edit="onEditOtherOverhead"
                  @copy="onCopyOtherOverhead"
                  @delete="onDeleteOtherOverhead"
                />
              </div>
            </el-col>
            <!-- /Grid Items -->
          </el-row>
          <!-- Show All -->
          <el-row v-if="idea.storyMode.forecast.otherOverheads.otherOverheads.length > 4">
            <el-col>
              <show-all-toggle
                :show="showAllOtherOverheadsGrid"
                @click="showAllOtherOverheadsGrid = !showAllOtherOverheadsGrid"
              />
            </el-col>
          </el-row>
          <!-- /Show All -->
        </div>
      </div>
      <!-- /Content -->

      <!-- Add More Details -->
      <add-more-details
        :category="'forecast'"
        :step="'other-overheads'"
        :concept-name="'otherOverheadsMoreDetails'"
        :completed-step-name="'otherOverheadsCompleted'"
      />
      <!-- /Add More Details -->

      <dialog-add-other-overhead
        v-if="loaded.otherOverheads || dialogAddOtherOverheadVisible"
        :dialog-add-other-overhead-visible="dialogAddOtherOverheadVisible"
        :other-overhead-action="otherOverheadAction"
        :options="addOtherOverheadOptions"
        @close-dialog-add-other-overhead="onCloseDialogAddOtherOverhead"
        @close-dialog-on-button="dialogAddOtherOverheadVisible = false"
        @on-update-other-overheads-view="getOtherOverheads"
      />
    </div>
    <!-- /Container -->

    <!-- Main Bottom Bar -->
    <!-- TODO: step-filled should be populated -->
    <main-bottom-bar
      :back="backNavigation"
      :next="nextNavigation"
      :completed-step-name="'otherOverheadsCompleted'"
      :step-filled="idea.storyMode.forecast.otherOverheads.otherOverheads.length > 0"
      @complete-step="onCompleteStep"
    />
    <!-- /Main Bottom Bar -->

    <!-- Info Guide Drawer -->
    <info-guide-drawer
      type="otherOverheadsExamples"
      :visible="guideVisible"
      @close="guideVisible = false"
    />
    <!-- /Info Guide Drawer -->

    <!-- Ai Buddy Drawer -->
    <ai-buddy-drawer
      :visible="aiBuddyDrawerVisible"
      type="other-overheads"
      @close="aiBuddyDrawerVisible = false"
    />
    <!-- /Ai Buddy Drawer -->

    <!-- Dialog Process Completed -->
    <dialog-process-completed
      :dialog-process-completed-visible="dialogProcessCompletedVisible"
      @close-dialog-process-completed="onCloseDialogProcessCompleted"
    />
    <!-- /Dialog Process Completed -->

    <!-- Dialog Remove Examples -->
    <dialog-remove-examples
      :visible="dialogRemoveExamplesVisible"
      :loading="removeExamplesLoading"
      @no="dialogRemoveExamplesVisible = false"
      @yes="deleteExamples"
    />
    <!-- /Dialog Remove Examples -->

  </div>
  <!-- /Other Overhead -->

</template>

<script>
import AddMoreDetails from '@/views/Home/StoryMode/Components/AddMoreDetails'
import AddNewCard from '@/components/_v2/AddNewCard'
import AiBuddyDrawer from '@/views/Home/StoryMode/Components/AiBuddy/AiBuddyDrawer.vue'
import BusinessGuideHeader from '@/components/_v2/Common/BusinessGuideHeader'
import CompletedOverlay from '@/views/Home/StoryMode/Components/CompletedOverlay'
import DashboardHeader from '@/views/Home/StoryMode/Components/DashboardHeader'
import DialogAddOtherOverhead from './Dialogs/DialogAddOtherOverhead'
import DialogProcessCompleted from '@/views/Home/StoryMode/Components/Dialogs/DialogProcessCompleted'
import DialogRemoveExamples from '@/views/Home/StoryMode/Components/DialogRemoveExamples'
import DistributionOfOtherOverheads from '@/views/Home/StoryMode/FinancialPlan/Components/Expenses/OtherOverheads/DistributionOfOtherOverheads'
import EraseIconButton from '@/components/_v2/EraseIconButton'
import Loader from '@/components/Loader'
import MainBottomBar from '@/views/Home/StoryMode/Components/MainBottomBar/MainBottomBar'
import MainNavigation from '@/views/Home/StoryMode/Components/MainNavigation/MainNavigation'
import MixinApiCalculations from '@/mixins/apiCalculations'
import MixinCurrencies from '@/mixins/currencies'
import MixinDeleteIdeaExamples from '@/mixins/deleteIdeaExamples'
import MixinEraseTemplate from '@/mixins/eraseTemplate'
import MixinGuide from '@/mixins/guide'
import MixinIdeaRoles from '@/mixins/permissions/ideaRoles'
import MixinProcessCompleted from '@/mixins/processCompleted'
import MixinUserSubscription from '@/mixins/permissions/userSubscription'
import OtherOverheadTable from '@/views/Home/StoryMode/Forecast/OtherOverheads/Tables/OtherOverheadTable'
import OtherOverheadsOverTime from '@/views/Home/StoryMode/FinancialPlan/Components/Expenses/OtherOverheads/OtherOverheadsOverTime'
import SetUpAndProjectionsCard from '@/views/Home/StoryMode/Components/Cards/SetUpAndProjectionsCard'
import ShowAllToggle from '@/views/Home/StoryMode/Components/ShowAllToggle'
import ViewSwitcher from '@/components/_v2/ViewSwitcher'
import addOtherOverheadOptions from '@/views/Home/StoryMode/Forecast/OtherOverheads/Dialogs/addOtherOverheadOptions'

import { mapActions, mapGetters, mapState } from 'vuex'
import BusinessGuideSearch from '@/views/Home/Components/BusinessGuideSearch.vue'
import InfoGuide from '@/views/Home/StoryMode/Components/Example/InfoGuide.vue'
import InfoGuideDrawer from '@/views/Home/StoryMode/Components/Example/InfoGuideDrawer.vue'

export default {
  name: 'StoryModeOtherOverheads',

  components: {
    InfoGuide,
    AiBuddyDrawer,
    InfoGuideDrawer,
    BusinessGuideSearch,
    AddMoreDetails,
    AddNewCard,
    BusinessGuideHeader,
    CompletedOverlay,
    DashboardHeader,
    DialogAddOtherOverhead,
    DialogProcessCompleted,
    DialogRemoveExamples,
    DistributionOfOtherOverheads,
    EraseIconButton,
    Loader,
    MainBottomBar,
    MainNavigation,
    OtherOverheadTable,
    OtherOverheadsOverTime,
    SetUpAndProjectionsCard,
    ShowAllToggle,
    ViewSwitcher
  },

  mixins: [
    MixinCurrencies,
    MixinApiCalculations,
    MixinProcessCompleted,
    MixinGuide,
    MixinDeleteIdeaExamples,
    MixinIdeaRoles,
    MixinEraseTemplate,
    MixinUserSubscription
  ],

  beforeRouteEnter (to, from, next) {
    next(vm => {
      if (vm.subscriptionIsFreeTrial) next({ name: 'home' })
    })
  },

  data () {
    return {
      aiBuddyDrawerVisible: false,
      searchValue: '',
      dialogRemoveExamplesVisible: false,
      removeExamplesLoading: false,
      guideVisible: false,
      dialogAddOtherOverheadVisible: false,
      showAllOtherOverheadsGrid: false,
      showAllOtherOverheadsList: false,
      dialogProcessCompletedVisible: false,
      otherOverheadAction: null,
      activeName: 'first',
      dashboardOtherOverheads: {
        title: 'Other Overheads'
      },
      gridActive: true,
      listActive: false,
      addOtherOverheadOptions
    }
  },

  computed: {
    ...mapState(['idea']),

    ...mapGetters('idea', {
      otherOverheads: 'getOtherOverheads',
      getStepIsCompleted: 'getStepIsCompleted',
      getHasItemsFromTemplate: 'getHasItemsFromTemplate'
    }),

    isOtherOverheadEmpty () {
      return !this.otherOverheads.length
    },

    isOtherOverheadCompleted () {
      return this.getStepIsCompleted('otherOverheadsCompleted')
    },

    otherOverheadToShowGrid () {
      if (this.searchValue !== '' && this.searchValue) {
        return this.filterOtherOverheads
      }

      return this.showAllOtherOverheadsGrid === true
        ? this.otherOverheads
        : this.otherOverheads.slice(0, 4)
    },

    otherOverheadToShowList () {
      if (this.searchValue !== '' && this.searchValue) {
        return this.filterOtherOverheads
      }

      return this.showAllOtherOverheadsList === true
        ? this.otherOverheads
        : this.otherOverheads.slice(0, 7)
    },

    filterOtherOverheads () {
      return this.otherOverheads.filter(item => item.name.toUpperCase().includes(this.searchValue.toUpperCase()))
    },

    ideaStartingFrom () {
      return this.idea.ideaSettings.startingFrom
    },

    removeButtonVisible () {
      return this.canEdit && this.getHasItemsFromTemplate('getOtherOverheads')
    },

    backNavigation () {
      return {
        title: this.$t('pages.businessGuide.navigation.marketingBudget'),
        route: 'business-guide-projections-marketing-budget'
      }
    },

    nextNavigation () {
      return {
        title: this.$t('pages.businessGuide.navigation.profitAndCashFlow'),
        route: 'business-guide-projections-profit-and-cash-flow'
      }
    }
  },

  watch: {
    ideaStartingFrom () {
      this.getOtherOverheads()
    }
  },

  async created () {
    await this.getOtherOverheads()
    await this.getOtherOverheadsMoreDetails()
    this.openGuide('otherOverheadsGuide')
  },

  methods: {
    ...mapActions('idea', [
      'getOtherOverheadsMoreDetails',
      'deleteStoryModeOtherOverhead',
      'deleteIdeaExamples'
    ]),

    checkOtherOverheads () {
      if (!this.canEdit) return
      if (this.isOtherOverheadCompleted) {
        this.dialogProcessCompletedVisible = true
      }
    },

    onAddOtherOverhead () {
      if (!this.canEdit) return
      this.dialogAddOtherOverheadVisible = true
    },

    onCloseDialogAddOtherOverhead () {
      this.dialogAddOtherOverheadVisible = false
      this.otherOverheadAction = null

      // Workaround to update computed property otherOverheadToShowGrid & otherOverheadToShowList
      this.updateOtherOverheadView()
    },

    gridVisible () {
      this.gridActive = !this.gridActive
      this.listActive = !this.listActive
    },

    listVisible () {
      this.listActive = !this.listActive
      this.gridActive = !this.gridActive
    },

    onGuideExampleVisible () {
      this.guideVisible = !this.guideVisible
    },

    onActivateView (view) {
      this.$store.commit('itemsView/setOtherOverheadsView', view)
    },

    updateOtherOverheadView () {
      this.showAllOtherOverheadsList = !this.showAllOtherOverheadsList
      this.showAllOtherOverheadsList = !this.showAllOtherOverheadsList
      this.showAllOtherOverheadsGrid = !this.showAllOtherOverheadsGrid
      this.showAllOtherOverheadsGrid = !this.showAllOtherOverheadsGrid
    },

    onEditOtherOverhead (otherOverhead) {
      this.otherOverheadAction = Object.assign({}, otherOverhead)
      this.dialogAddOtherOverheadVisible = true
    },

    onCopyOtherOverhead (otherOverhead) {
      this.otherOverheadAction = Object.assign({}, otherOverhead)
      this.otherOverheadAction.id = 0
      this.dialogAddOtherOverheadVisible = true
    },

    onDeleteOtherOverhead (otherOverhead) {
      this.loaded.otherOverheads = false
      this.deleteStoryModeOtherOverhead(otherOverhead.id)
        .then(() => {
          this.getOtherOverheads()
        })

      // Return to list view, if there are no other overhead
      if (this.idea.storyMode.forecast.otherOverheads.otherOverheads.length === 0) {
        this.onActivateView('list')
      }
    },

    otherOverheadDate (otherOverhead) {
      if (otherOverhead.oneTime) {
        return this.getFormattedDate(otherOverhead.whenWillItOccur)
      }

      return `${this.getFormattedDate(otherOverhead.starts)} - ${this.getFormattedDate(otherOverhead.ends)}`
    },

    otherOverheadAmount (otherOverhead) {
      if (otherOverhead.average && otherOverhead.oneTime === false) {
        return this.getFormattedAmount(otherOverhead.average)
      }

      return this.getFormattedAmount(otherOverhead.amount)
    },

    getFormattedDate (date) {
      return this.$moment(date).format('MMM YYYY')
    },

    onEraseFromTemplate () {
      if (this.isOtherOverheadCompleted) {
        this.openedDialogProcessCompleted()

        return
      }
      this.dialogRemoveExamplesVisible = true
    },

    deleteExamples () {
      this.removeExamplesLoading = true
      this.deleteIdeaExamples('other-overheads')
        .then(response => {
          const { type, ids } = response
          this.deleteFromState({ type, ids })
          this.getOtherOverheads()
          this.dialogRemoveExamplesVisible = false
          this.removeExamplesLoading = false
        }).catch(_ => { this.removeExamplesLoading = false })
    }
  }
}
</script>

<style scoped>
  #story-mode-other-overheads {
    margin-left: 0;
  }
</style>
